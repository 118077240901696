<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t("TreeViews.EditTree", {}, { locale: this.$store.state.activeLang })
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && treeViewData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && treeViewData.isOk"
    class="form-tree-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t("TreeViews.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="treeViewData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "TreeViews.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          :disabled="true"
          v-model="treeViewData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="viewFilter" class="form-label required">{{
          $t(
            "TreeViews.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
              this.$route.params.customObjectId
            )
          "
          :selectedData="selectedViewFilter"
          :data="viewFilterData"
          @onChange="onChangeViewFilter"
          :allowEmpty="false"
          :isParameters="true"
        />
      </div>
      <div class="col col-md-6">
        <label for="headerField" class="form-label">{{
          $t(
            "TreeViews.HeaderField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :selectedData="selectedHeaderField"
          :data="headerFieldData"
          @onChange="onChangeHeaderField"
          :isParameters="true"
          :resetSelect="selectHeaderFieldReset"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <div class="form-check">
          <label for="IsActive" class="form-label">{{
            $t(
              "BaseModelFields.IsActive",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="treeViewData.isActive"
          />
        </div>
      </div>
    </div>
    <LookupRelationTable
      v-if="getDataFinished"
      :data="treeViewData"
      :customObjectPublicId="this.$route.params.customObjectId"
      @setTreeItems="getTreeItems"
    />
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
import LookupRelationTable from "@/components/custom/tree-view/LookupRelationTable.vue";
export default {
  name: "CustomObjectEditTree",
  components: { LookupRelationTable },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      treeViewData: {},
      selectedViewFilter: [],
      selectedHeaderField: [],
      selectedLookupRelation: [],
      selectedChildField: [],
      headerFieldData: [],
      viewFilterData: [],
      childFieldData: [],
      nullObject: [{ key: null, value: null }],
      treeViewItemData: {},
      getDataFinished: true,
      selectHeaderFieldReset: false,
      detailRequestEnd: false,
    };
  },
  methods: {
    onChangeViewFilter: function (selected) {
      this.selectHeaderFieldReset = !this.selectHeaderFieldReset;
      var self = this;
      if (selected.key) {
        this.$prodGatewayAxios
          .get(
            String.format(
              "/Lcdp-SummaryFiltersFields?coId={0}&viewFilterId={1}",
              this.$route.params.customObjectId,
              selected.key
            )
          )
          .then((response) => {
            self.headerFieldData = response.data.items;
          });
      }

      self.treeViewData.viewFilterPublicId = selected.key;
      self.treeViewData.viewFilterName = selected.value;
    },
    onChangeHeaderField: function (selected) {
      this.treeViewData.headerFieldPublicId = selected.key;
      this.treeViewData.headerFieldName = selected.value;
    },
    getViewFilterData() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-ViewFilterListGeneralViewFilters?customObjectPublicId={0}&isAll=false",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          var items = response.data.items;
          items.forEach(function (v) {
            var data = { key: v.publicId, value: v.name };
            self.viewFilterData.push(data);
          });
        });
    },
    getTreeItems(treeItems) {
      this.treeViewData.treeItems = treeItems;
    },
    onSubmit(buttonName) {
      var form = $(".form-tree-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-TreeViewUpdate", { ...this.treeViewData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/TreeViews/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getTreeViewDetail() {
      this.getDataFinished = false;
      var self = this;
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-TreeViewGet?id={0}&coId={1}",
            this.$route.params.treeViewId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.treeViewData = response.data;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            if (
              !String.isNullOrWhiteSpace(
                this.treeViewData.viewFilterPublicId
              ) &&
              !String.isNullOrWhiteSpace(this.treeViewData.viewFilterName)
            ) {
              this.selectedViewFilter.push({
                key: this.treeViewData.viewFilterPublicId,
                value: this.treeViewData.viewFilterName,
              });
              this.$prodGatewayAxios
                .get(
                  String.format(
                    "/Lcdp-SummaryFiltersFields?coId={0}&viewFilterId={1}",
                    this.$route.params.customObjectId,
                    self.treeViewData.viewFilterPublicId
                  )
                )
                .then((response) => {
                  self.headerFieldData = response.data.items;
                });
            }
            if (
              !String.isNullOrWhiteSpace(
                this.treeViewData.headerFieldPublicId
              ) &&
              !String.isNullOrWhiteSpace(this.treeViewData.headerFieldName)
            ) {
              this.selectedHeaderField.push({
                key: this.treeViewData.headerFieldPublicId,
                value: this.treeViewData.headerFieldName,
              });
            }

            if (this.treeViewData.treeItems.length == 0) {
              this.treeViewItemData.customObjectPublicId =
                this.$route.params.customObjectId;
              this.treeViewData.treeItems.push(this.treeViewItemData);
            }
            this.getDataFinished = true;
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getTreeViewDetail();
    this.getViewFilterData();
  },
};
</script>
